import { ButtonLoading } from '@tovala/component-library'
import {
  Customer,
  CustomerCreditCard,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Elements } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  ErrorCodeMessageMapCombinedAPI,
  ReactivateLocationState,
} from 'types/internal'
import { getElementsConfiguration, stripePromise } from 'utils/stripe'
import { wrapWithContactSupportTeam } from 'utils/errors'

import {
  useAddPaymentSource,
  useDeletePaymentSource,
  useEditDefaultPaymentSource,
} from 'hooks/combinedAPI/payments'
import { useCustomer } from 'hooks/combinedAPI/customers'
import { useStripeSubmission } from 'hooks/stripe'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import CreditCardIcon from 'components/common/icons/CreditCardIcon'
import CreditCardInput from 'components/common/CreditCardInput'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import ProfileHeading from './AccountHeading'
import RadioAsync from 'components/common/RadioAsync'

const ADD_PAYMENT_METHOD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't add your payment method due to a technical issue on our end.",
  },
  'StripeError-card_error': {
    helpToFix: 'Please try using different payment credentials.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't add your payment method because it was declined.",
  },
  'StripeError-CardError': {
    helpToFix: 'Please try using different payment credentials.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't add your payment method because it was declined.",
  },
  UnknownError: {
    wayOut: '',
    why: 'There was an issue adding your card, so please check your card details and try again.',
  },
  RateLimitReached: {
    helpToFix: 'Please wait 1 hour to try again',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "You've exceeded the number of allowed entries.",
  },
}

const DELETE_PAYMENT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't delete your payment source due to a technical issue on our end.",
  },
}

const EDIT_DEFAULT_PAYMENT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your default payment source due to a technical issue on end.",
  },
}

const LOAD_CUSTOMER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load your information due to a technical issue on our end.",
  },
}

const PaymentPage = () => {
  const { user } = useUser()

  const {
    data: customer,
    error: loadCustomerError,
    isError: hasLoadCustomerError,
  } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  return (
    <Elements options={getElementsConfiguration()} stripe={stripePromise}>
      <h1 className="mb-10 text-k/44_110 md:hidden">Payment</h1>
      <div className="hidden md:block">
        <ProfileHeading to="/account">Payment</ProfileHeading>
      </div>

      {customer ? (
        <PaymentPageLoaded customer={customer} user={user} />
      ) : hasLoadCustomerError ? (
        <APIErrorDisplay
          error={loadCustomerError}
          errorCodeMessageMap={LOAD_CUSTOMER_ERRORS}
        />
      ) : null}
    </Elements>
  )
}

export default PaymentPage

const PaymentPageLoaded = ({
  customer,
  user,
}: {
  customer: Customer
  user: UserV1
}) => {
  const location = useLocation()

  const [editingDefaultPaymentSourceID, setEditingDefaultPaymentSourceID] =
    useState('')
  const [reactivateError, setReactivateError] = useState(() => {
    return (location.state as ReactivateLocationState | undefined)
      ?.resubscribeError
  })

  const { isProcessingSubmit, onSubmit, submissionError } = useStripeSubmission(
    {
      action: 'add your payment method',
      onTokenCreated(stripeToken) {
        return addPaymentSource({
          customerID: user.subscription.customerID,
          data: { setAsDefault: true, stripeToken },
          userID: user.id,
        })
      },
    }
  )

  const {
    error: addPaymentSourceError,
    isError: hasAddPaymentSourceError,
    isLoading: isAddingPaymentSource,
    mutateAsync: addPaymentSource,
  } = useAddPaymentSource({
    onSuccess: () => {
      // It's confusing to the user if we leave an error display after they have saved
      // their payment information so we clear it out here.
      setReactivateError('')
    },
  })

  const {
    error: editDefaultPaymentSourceError,
    isError: hasEditDefaultPaymentSourceError,
    isLoading: isEditingDefaultPaymentSource,
    mutate: editDefaultPaymentSource,
  } = useEditDefaultPaymentSource()

  const defaultCard = customer.sources.data.find(
    (card) => customer.default_source === card.id
  )

  return (
    <div className="space-y-10 md:space-y-6 md:px-4 md:py-6">
      {reactivateError && <ErrorDisplay wayOut={null} why={reactivateError} />}

      <div className="space-y-4">
        {customer.sources.data.map((card) => {
          return (
            <SavedCreditCard
              key={card.id}
              card={card}
              defaultCardID={defaultCard?.id ?? ''}
              isEditingDefaultPaymentSource={
                isEditingDefaultPaymentSource &&
                editingDefaultPaymentSourceID === card.id
              }
              onChangeDefaultPaymentSource={(cardID) => {
                setEditingDefaultPaymentSourceID(cardID)

                editDefaultPaymentSource({
                  customerID: user.subscription.customerID,
                  data: { cardID },
                  userID: user.id,
                })
              }}
              user={user}
            />
          )
        })}

        {hasEditDefaultPaymentSourceError && (
          <div className="w-2/5 md:w-full">
            <APIErrorDisplay
              error={editDefaultPaymentSourceError}
              errorCodeMessageMap={EDIT_DEFAULT_PAYMENT_ERRORS}
            />
          </div>
        )}
      </div>

      <div className="w-[300px] md:w-full">
        {/*
         * The "cc-input-container" test ID is needed so we can target the Stripe card element
         * accurately in our end-to-end tests.
         */}
        <form
          data-testid="cc-input-container"
          onSubmit={(event) => {
            // We don't want to let default form submission happen here, which would refresh the page.
            event.preventDefault()

            onSubmit()
          }}
        >
          <div className="space-y-4">
            <FormGroup label="Add a New Card">
              <CreditCardInput />
            </FormGroup>

            {hasAddPaymentSourceError ? (
              <APIErrorDisplay
                error={addPaymentSourceError}
                errorCodeMessageMap={ADD_PAYMENT_METHOD_ERRORS}
              />
            ) : submissionError ? (
              <ErrorDisplay {...submissionError} />
            ) : null}
          </div>

          <div className="mt-6">
            <ButtonLoading
              isLoading={isProcessingSubmit || isAddingPaymentSource}
              size="large"
              type="submit"
            >
              Save Payment
            </ButtonLoading>
          </div>
        </form>
      </div>
    </div>
  )
}

const SavedCreditCard = ({
  card,
  defaultCardID,
  isEditingDefaultPaymentSource,
  onChangeDefaultPaymentSource,
  user,
}: {
  card: CustomerCreditCard
  defaultCardID: string
  isEditingDefaultPaymentSource: boolean
  onChangeDefaultPaymentSource(cardID: string): void
  user: UserV1
}) => {
  const {
    error: deletePaymentSourceError,
    isError: hasDeletePaymentSourceError,
    isLoading: isDeletingPaymentSource,
    mutate: deletePaymentSource,
  } = useDeletePaymentSource()

  return (
    <>
      <div className="flex items-center space-x-6 md:space-x-4">
        <RadioAsync
          checked={defaultCardID === card.id}
          disabled={isDeletingPaymentSource}
          isLoading={isEditingDefaultPaymentSource}
          name="default-payment-card"
          onChange={() => {
            onChangeDefaultPaymentSource(card.id)
          }}
        />

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-3 md:space-x-2">
            <div className="w-14">
              <CreditCardIcon brand={card.brand} />
            </div>
            <span
              aria-label={`Credit card last 4 ${card.last4}`}
              className="text-k/18_120 md:text-h/14_120"
            >
              {card.last4}
            </span>
          </div>

          {defaultCardID !== card.id && (
            <ButtonLoading
              buttonStyle="stroke"
              disabled={isEditingDefaultPaymentSource}
              isLoading={isDeletingPaymentSource}
              onClick={() => {
                deletePaymentSource({
                  customerID: user.subscription.customerID,
                  paymentSourceID: card.id,
                  userID: user.id,
                })
              }}
              size="small"
            >
              Remove
            </ButtonLoading>
          )}
        </div>
      </div>

      {hasDeletePaymentSourceError && (
        <div className="w-2/5 md:w-full">
          <APIErrorDisplay
            error={deletePaymentSourceError}
            errorCodeMessageMap={DELETE_PAYMENT_ERRORS}
          />
        </div>
      )}
    </>
  )
}
