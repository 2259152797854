import { FloatingOverlay, Placement } from '@floating-ui/react'
import { ReactNode, useEffect } from 'react'

import {
  Popover,
  PopoverContent,
  PopoverDescription,
  PopoverHeading,
  PopoverClose,
  PopoverTrigger,
} from 'components/common/Popover'

const MenuTutorialPopover = ({
  children,
  description,
  heading,
  isOpen,
  onOpenChange,
  placement = 'top',
}: {
  children: ReactNode
  description: string
  heading: string
  isOpen: boolean
  onOpenChange(isOpen: boolean): void
  placement?: Placement
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Popover
      onOpenChange={onOpenChange}
      open={isOpen}
      placement={placement}
      popoverStyle="dark"
    >
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      {isOpen && (
        <FloatingOverlay
          className="z-30 h-full w-full bg-black opacity-20"
          lockScroll
        >
          <PopoverContent className="z-40">
            <div className="absolute right-3">
              <PopoverClose />
            </div>
            <div className="space-y-2">
              <PopoverHeading>{heading}</PopoverHeading>
              <PopoverDescription>{description}</PopoverDescription>
            </div>
          </PopoverContent>
        </FloatingOverlay>
      )}
    </Popover>
  )
}

export default MenuTutorialPopover
