import { MealFilter } from '@tovala/browser-apis-combinedapi'
import { MenuComponentsStandardized } from '@tovala/browser-apis-menu-components'
import {
  CaretDownIcon,
  Checkbox,
  Radio,
  XIcon,
} from '@tovala/component-library'
import useEmblaCarousel from 'embla-carousel-react'
import { ReactNode, useEffect } from 'react'
import { SelectedMealFilters } from 'types/internal'
import {
  featuredDietFilters,
  filtersTest,
  menuSortOptions,
  DISPLAY_STRING_MAP,
} from 'utils/menuFiltersTest'
import {
  getFilteredComponentsCounts,
  getHasSelectedFilters,
  getMealIDsInFilters,
  getSelectedMealFiltersMetadata,
} from 'utils/menus'

import { events } from 'analytics/events'
import { clsx } from 'clsx'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  usePopoverContext,
} from 'components/common/Popover'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import { track } from 'utils/analytics'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

const MenuMealsWithFilters = ({
  allMealFilters,
  allMealIDs,
  mealGroups,
  mealsInFilters,
  menuComponentsGrid,
  onChangeSelectedFilters,
  onChangeSelectedMenuSort,
  selectedFilters,
  selectedMenuSort,
}: {
  allMealFilters: MealFilter[]
  allMealIDs: number[]
  mealGroups: {
    components: MenuComponentsStandardized
    description: string | null
    id: 'filtered' | 'everythingElse'
    title: string | null
  }[]
  mealsInFilters: {
    key: string
    mealsInFilter: number[]
  }[]
  menuComponentsGrid(opts: {
    components: MenuComponentsStandardized
  }): ReactNode
  onChangeSelectedFilters(filters: SelectedMealFilters): void
  onChangeSelectedMenuSort(sortOption: string | null): void
  selectedFilters: SelectedMealFilters
  selectedMenuSort: string | null
}) => {
  return (
    <div>
      <MenuFilters
        allMealFilters={allMealFilters}
        allMealIDs={allMealIDs}
        filters={filtersTest}
        mealsInFilters={mealsInFilters}
        onChangeSelectedFilters={onChangeSelectedFilters}
        onChangeSelectedMenuSort={onChangeSelectedMenuSort}
        selectedFilters={selectedFilters}
        selectedMenuSort={selectedMenuSort}
      />
      {mealGroups.map(({ components, id, title }) => {
        return (
          <MealGroup key={id}>
            <div className="mx-auto max-w-menu lg:px-4 md:px-0">
              <div className="space-y-10 md:space-y-6">
                <MealGroupTitle
                  quantityResults={getFilteredComponentsCounts({
                    components,
                  })}
                  title={id === 'filtered' ? 'Your filtered menu' : title}
                />

                {menuComponentsGrid({ components })}
              </div>
            </div>
          </MealGroup>
        )
      })}
    </div>
  )
}

export default MenuMealsWithFilters

const MealGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div className="py-10 first:pt-0 last:pb-0 even:pb-10 md:py-6">
      {children}
    </div>
  )
}

const MealGroupTitle = ({
  quantityResults,
  title,
}: {
  quantityResults: number | null
  title: string | null
}) => {
  if (!title) {
    return null
  }

  return (
    <div className="space-y-3 md:px-4">
      <div className="flex items-center justify-between">
        <h2 className="text-k/24_120">
          {title} ({quantityResults})
        </h2>
      </div>
    </div>
  )
}

const MenuFilters = ({
  allMealFilters,
  allMealIDs,
  filters,
  mealsInFilters,
  onChangeSelectedFilters,
  onChangeSelectedMenuSort,
  selectedFilters,
  selectedMenuSort,
}: {
  allMealFilters: MealFilter[]
  allMealIDs: number[]
  filters: MealFilter[]
  mealsInFilters: {
    key: string
    mealsInFilter: number[]
  }[]
  onChangeSelectedFilters(filters: SelectedMealFilters): void
  onChangeSelectedMenuSort(sortOption: string | null): void
  selectedFilters: SelectedMealFilters
  selectedMenuSort: string | null
}) => {
  const slidesToScroll = useVariantByScreenSize<'auto' | 1>('auto', {
    md: 1,
  })

  const [filtersContainer, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      slidesToScroll,
      inViewThreshold: 1,
      axis: 'x',
      skipSnaps: true,
    },
    [
      WheelGesturesPlugin({
        forceWheelAxis: 'x',
      }),
    ]
  )

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('scroll', (emblaApi) => {
        const {
          limit,
          target,
          location,
          offsetLocation,
          scrollTo,
          translate,
          scrollBody,
        } = emblaApi.internalEngine()

        let edge: number | null = null

        if (limit.reachedMax(location.get())) {
          edge = limit.max
        }
        if (limit.reachedMin(location.get())) {
          edge = limit.min
        }

        if (edge !== null) {
          offsetLocation.set(edge)
          location.set(edge)
          target.set(edge)
          translate.to(edge)
          translate.toggleActive(false)
          scrollBody.useDuration(0).useFriction(0)
          scrollTo.distance(0, false)
        } else {
          translate.toggleActive(true)
        }
      })
    }
  }, [emblaApi])

  const trackFilterClick = ({
    filterKey,
    type,
    updatedSelectedFilters,
  }: {
    filterKey: string
    type: 'disabled' | 'enabled'
    updatedSelectedFilters: SelectedMealFilters
  }) => {
    let mealIDCount = 0

    if (getHasSelectedFilters(updatedSelectedFilters)) {
      const mealIDsInFilters = getMealIDsInFilters(
        updatedSelectedFilters,
        allMealFilters,
        mealsInFilters,
        allMealIDs
      )

      mealIDCount = mealIDsInFilters.length
    }

    const event =
      type === 'disabled' ? events.DISABLES_FILTER : events.ENABLES_FILTER
    track(event, {
      filter_id: filterKey,
      matching_meal_count: mealIDCount,
    })
  }

  return (
    <div className="z-5 relative mx-auto max-w-menu lg:px-4 md:px-0 md:pl-4">
      <div
        ref={filtersContainer}
        // increase padding to give more click area for dragging, will adjust spacing without negative margins if we end up using this as the new filter design
        className="-my-4 overflow-hidden py-4 md:pr-4"
      >
        <div className="flex items-center space-x-2">
          <div className="mr-2 border-r border-black pr-4">
            <FilterGroup
              displayString="Sort"
              hasSelectedFilters={selectedMenuSort !== null}
              onClearFilterGroup={() => {
                onChangeSelectedMenuSort(null)

                const { enabled_filters, enabled_filters_count } =
                  getSelectedMealFiltersMetadata()
                const metadata = {
                  enabled_filters,
                  enabled_filters_count,
                  sort_option: 'none',
                }

                track(events.DISABLES_SORT, metadata)
              }}
            >
              {menuSortOptions.map(({ displayString, key }) => {
                const isSelected =
                  key === 'featured'
                    ? selectedMenuSort === null
                    : selectedMenuSort === key
                return (
                  <FilterGroupOption
                    key={key}
                    displayString={displayString}
                    isSelected={isSelected}
                    onChangeOption={() => {
                      onChangeSelectedMenuSort(key === 'featured' ? null : key)

                      const { enabled_filters, enabled_filters_count } =
                        getSelectedMealFiltersMetadata()
                      const metadata = {
                        enabled_filters,
                        enabled_filters_count,
                        sort_option: key === 'featured' ? 'none' : key,
                      }
                      const event =
                        key === 'featured'
                          ? events.DISABLES_SORT
                          : events.ENABLES_SORT

                      track(event, metadata)
                    }}
                    type="radio"
                  />
                )
              })}
            </FilterGroup>
          </div>

          {filters.map(({ displayString, filters }, index) => {
            const filterGroupSelectedFilters =
              selectedFilters[displayString] || []

            if (filters.length === 1) {
              const filterOption = filters[0].options[0]
              const isSelected = filterGroupSelectedFilters.includes(
                filterOption.key
              )

              return (
                <SingleFilter
                  key={`${displayString}-${index}`}
                  displayString={filterOption.displayString}
                  isSelected={isSelected}
                  onClickFilter={() => {
                    let updatedSelectedFilters = {}
                    if (isSelected) {
                      const isSelected = filterGroupSelectedFilters.includes(
                        filterOption.key
                      )

                      const updatedFilterGroup = {
                        [displayString]: isSelected
                          ? filterGroupSelectedFilters.filter(
                              (k) => k !== filterOption.key
                            )
                          : [...filterGroupSelectedFilters, filterOption.key],
                      }

                      updatedSelectedFilters = {
                        ...selectedFilters,
                        ...updatedFilterGroup,
                      }
                    } else {
                      updatedSelectedFilters = {
                        ...selectedFilters,
                        [displayString]: [
                          ...filterGroupSelectedFilters,
                          filterOption.key,
                        ],
                      }
                    }

                    onChangeSelectedFilters(updatedSelectedFilters)

                    trackFilterClick({
                      filterKey: filterOption.key,
                      type: isSelected ? 'disabled' : 'enabled',
                      updatedSelectedFilters,
                    })
                  }}
                />
              )
            } else if (filters.length > 1) {
              const hasSelectedFilters = filterGroupSelectedFilters.length > 0

              const firstSelectedFilter = filters.find((f) =>
                f.options.find(
                  (option) => option.key === filterGroupSelectedFilters[0]
                )
              )

              const filterGroupDisplayString =
                hasSelectedFilters && firstSelectedFilter
                  ? firstSelectedFilter.options[0].displayString
                  : displayString
              return (
                <FilterGroup
                  key={`${displayString}-${index}`}
                  displayString={filterGroupDisplayString}
                  hasSelectedFilters={hasSelectedFilters}
                  onClearFilterGroup={() => {
                    const updatedFilterGroup = {
                      [displayString]: [],
                    }

                    const updatedSelectedFilters = {
                      ...selectedFilters,
                      ...updatedFilterGroup,
                    }

                    onChangeSelectedFilters(updatedSelectedFilters)
                  }}
                  selectedFilterCount={filterGroupSelectedFilters.length}
                >
                  {filters.map(({ options }) => {
                    const filterOption = options[0]

                    const isSelected = filterGroupSelectedFilters.includes(
                      filterOption.key
                    )
                    return (
                      <FilterGroupOption
                        key={filterOption.key}
                        displayString={filterOption.displayString}
                        isSelected={isSelected}
                        onChangeOption={() => {
                          const updatedSelectedFilters = {
                            [displayString]: isSelected
                              ? filterGroupSelectedFilters.filter(
                                  (key) => key !== filterOption.key
                                )
                              : [
                                  ...filterGroupSelectedFilters,
                                  filterOption.key,
                                ],
                          }

                          onChangeSelectedFilters({
                            ...selectedFilters,
                            ...updatedSelectedFilters,
                          })

                          trackFilterClick({
                            filterKey: filterOption.key,
                            type: isSelected ? 'disabled' : 'enabled',
                            updatedSelectedFilters,
                          })
                        }}
                        type="checkbox"
                      />
                    )
                  })}
                </FilterGroup>
              )
            }
          })}
          {featuredDietFilters.map((key) => {
            const filter = filters
              .find((f) => f.displayString === DISPLAY_STRING_MAP.diet)
              ?.filters.find((f) => f.options[0].key === key)
            if (!filter) {
              return null
            }

            const filterOption = filter.options[0]
            const filterGroupSelectedFilters =
              selectedFilters[DISPLAY_STRING_MAP.diet] || []
            const isSelected = filterGroupSelectedFilters.includes(key) || false

            return (
              <SingleFilter
                key={key}
                displayString={filterOption.displayString}
                isSelected={isSelected}
                onClickFilter={() => {
                  const updatedSelectedFilters = {
                    [DISPLAY_STRING_MAP.diet]: isSelected
                      ? filterGroupSelectedFilters.filter(
                          (key) => key !== filterOption.key
                        )
                      : [...filterGroupSelectedFilters, filterOption.key],
                  }

                  onChangeSelectedFilters({
                    ...selectedFilters,
                    ...updatedSelectedFilters,
                  })

                  trackFilterClick({
                    filterKey: filterOption.key,
                    type: isSelected ? 'disabled' : 'enabled',
                    updatedSelectedFilters,
                  })
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const SingleFilter = ({
  displayString,
  isSelected,
  onClickFilter,
}: {
  displayString: string
  isSelected: boolean
  onClickFilter(): void
}) => {
  return (
    <button
      className={clsx(
        'flex items-center space-x-2 whitespace-nowrap rounded-full border px-2 py-1 text-k/14_120',
        {
          'border-grey-8 bg-grey-0': !isSelected,
          'border-orange-2 bg-orange-2 text-white': isSelected,
        }
      )}
      onClick={onClickFilter}
      type="button"
    >
      <span>{displayString}</span>
      {isSelected && (
        <span className="block h-4 w-4 shrink-0">
          <XIcon />
        </span>
      )}
    </button>
  )
}

const FilterGroup = ({
  children,
  displayString,
  hasSelectedFilters,
  onClearFilterGroup,
  selectedFilterCount,
}: {
  children: ReactNode
  displayString: string
  hasSelectedFilters?: boolean
  onClearFilterGroup: () => void
  selectedFilterCount?: number
}) => {
  return (
    <div className="relative flex items-center justify-end">
      {hasSelectedFilters ? (
        <button
          className="absolute block h-6 w-6 shrink-0"
          onClick={() => {
            onClearFilterGroup()
          }}
          type="button"
        >
          <span className="block h-4 w-4 text-white">
            <XIcon />
          </span>
        </button>
      ) : null}

      <Popover hideArrow offset={4} placement="bottom-start">
        <PopoverTrigger>
          <span
            className={clsx(
              'flex items-center space-x-2 whitespace-nowrap rounded-full border border-grey-8 py-1 pl-3 pr-2 text-k/14_120',
              {
                'border-orange-2 bg-orange-2 text-white': hasSelectedFilters,
              }
            )}
          >
            <span className="block">{displayString}</span>
            {selectedFilterCount && selectedFilterCount > 1 ? (
              <span className="block">+{selectedFilterCount - 1}</span>
            ) : null}
            {!hasSelectedFilters ? (
              <span className="block h-4 w-4 shrink-0">
                <CaretDownIcon />
              </span>
            ) : (
              <span className="block h-4 w-4 shrink-0"></span>
            )}
          </span>
        </PopoverTrigger>

        <PopoverContent className="z-5">
          <div className="absolute min-w-[182px] space-y-5 rounded-xl bg-grey-0 p-4 shadow-[0px_6px_6px_0px_rgba(145,145,145,0.19),0px_12px_7px_0px_rgba(145,145,145,0.11),0px_22px_9px_0px_rgba(145,145,145,0.03),0px_35px_10px_0px_rgba(145,145,145,0)]">
            {children}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

const FilterGroupOption = ({
  displayString,
  isSelected,
  onChangeOption,
  type,
}: {
  displayString: string
  isSelected: boolean
  onChangeOption(checked: boolean): void
  type: 'checkbox' | 'radio'
}) => {
  const { setOpen } = usePopoverContext()

  return (
    <div className="flex items-center justify-between">
      <label
        className="flex grow cursor-pointer items-center whitespace-nowrap pr-4 text-k/14_120"
        htmlFor={displayString}
      >
        {displayString}
      </label>
      {type === 'checkbox' ? (
        <Checkbox
          checked={isSelected}
          id={displayString}
          name={displayString}
          onChange={(e) => {
            onChangeOption(e.target.checked)
          }}
        />
      ) : (
        <Radio
          checked={isSelected}
          id={displayString}
          name={displayString}
          onChange={(e) => {
            onChangeOption(e.target.checked)

            setOpen(false)
          }}
        />
      )}
    </div>
  )
}
