import {
  arrow,
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  Placement,
  FloatingPortal,
  FloatingFocusManager,
  useId,
  FloatingArrow,
} from '@floating-ui/react'
import { XIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  ButtonHTMLAttributes,
  Dispatch,
  HTMLProps,
  ReactNode,
  SetStateAction,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

interface PopoverOptions {
  hideArrow?: boolean
  initialOpen?: boolean
  modal?: boolean
  offset?: number
  onOpenChange?: (open: boolean) => void
  open?: boolean
  placement?: Placement
  popoverStyle?: 'dark' | 'unstyled'
}

const POPOVER_STYLE_MAP = {
  dark: 'max-w-xs rounded-xl bg-black py-4 pl-3 pr-10 text-white',
  unstyled: '',
}

export function usePopover({
  hideArrow = false,
  initialOpen = false,
  modal,
  offset: offsetValue = 20,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'top',
  popoverStyle = 'unstyled',
}: PopoverOptions = {}) {
  const arrowRef = useRef(null)

  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    middleware: [
      offset(offsetValue),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
      !hideArrow &&
        arrow({
          element: arrowRef,
        }),
    ],
    onOpenChange: setOpen,
    open,
    placement,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen === null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      ...(!hideArrow && { arrowRef }),
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      popoverStyle,
      setLabelId,
      setDescriptionId,
    }),
    [
      open,
      setOpen,
      interactions,
      data,
      modal,
      labelId,
      popoverStyle,
      descriptionId,
      hideArrow,
    ]
  )
}

type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: Dispatch<SetStateAction<string | undefined>>
      setDescriptionId: Dispatch<SetStateAction<string | undefined>>
    })
  | null

const PopoverContext = createContext<ContextType>(null)

export const usePopoverContext = () => {
  const context = useContext(PopoverContext)

  if (context === null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export function Popover({
  children,
  modal = false,
  ...restOptions
}: {
  children: ReactNode
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

interface PopoverTriggerProps {
  children: ReactNode
  asChild?: boolean
}

export const PopoverTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext()
  const ref = useMergeRefs([context.refs.setReference, propRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
      })
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      onClick={() => context.setOpen(!context.open)}
      {...context.getReferenceProps(props)}
      type="button"
    >
      {children}
    </button>
  )
})

export const PopoverContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(function PopoverContent({ style, ...props }, propRef) {
  const { arrowRef, context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!floatingContext.open) {
    return null
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          ref={ref}
          aria-describedby={context.descriptionId}
          aria-labelledby={context.labelId}
          style={{ ...context.floatingStyles, ...style }}
          {...context.getFloatingProps(props)}
        >
          <div
            className={clsx('relative', {
              [POPOVER_STYLE_MAP[context.popoverStyle]]: context.popoverStyle,
            })}
          >
            {props.children}
          </div>

          {arrowRef && (
            <FloatingArrow
              ref={arrowRef}
              context={floatingContext}
              fill="#39392D"
              height={12}
              width={24}
            />
          )}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})

export const PopoverHeading = forwardRef<
  HTMLHeadingElement,
  HTMLProps<HTMLHeadingElement>
>(function PopoverHeading(props, ref) {
  const { setLabelId } = usePopoverContext()
  const id = useId()

  // Only sets `aria-labelledby` on the Popover root element
  // if this component is mounted inside it.
  useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <h2 {...props} ref={ref} className="text-h/18_110 font-medium" id={id}>
      {props.children}
    </h2>
  )
})

export const PopoverDescription = forwardRef<
  HTMLParagraphElement,
  HTMLProps<HTMLParagraphElement>
>(function PopoverDescription(props, ref) {
  const { setDescriptionId } = usePopoverContext()
  const id = useId()

  // Only sets `aria-describedby` on the Popover root element
  // if this component is mounted inside it.
  useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  return <p {...props} ref={ref} className="text-body-sm" id={id} />
})

export const PopoverClose = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function PopoverClose(props, ref) {
  const { setOpen } = usePopoverContext()
  return (
    <button
      ref={ref}
      type="button"
      {...props}
      onClick={(event) => {
        props.onClick?.(event)
        setOpen(false)
      }}
    >
      <div className="h-6 w-6 text-white">
        <XIcon />
      </div>
    </button>
  )
})
