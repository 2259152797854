export const events = {
  ADD_PAYMENT_ATTEMPTED: 'add_payment_attempted',
  ADJUST_POP_UP_DISMISS: 'adjust_pop_up_dismiss',
  ADJUST_UPDATE_ORDER: 'adjust_update_order',
  ADJUST_UPDATE_ORDER_ALL: 'adjust_update_order_all',
  CANCELS_MEAL_PLAN: 'cancels_meal_plan',
  CHANGE_PAYMENT_METHOD: 'change_payment_method',
  CHANGE_SHIPPING_ADDRESS: 'change_shipping_address',
  CHANGES_PREMIUM_PREFERENCE: 'changes_premium_preference',
  CLOSED_POST_PURCHASE_SURVEY: 'closed_post_purchase_survey',
  CLOSED_WEB_ONBOARDING_MODAL: 'closed_web_onboarding_modal',
  COMPLETED_POST_PURCHASE_SURVEY: 'completed_post_purchase_survey',
  COSTCO_MEAL_BOX_ADD_TO_CART: 'costco_meal_box_add_to_cart',
  COSTCO_MEAL_BOX_OPEN_FAQ: 'costco_meal_box_open_faq',
  COSTCO_MEAL_BOX_SAVE_SHIPPING_ADDRESS:
    'costco_meal_box_save_shipping_address',
  COSTCO_MEAL_BOX_SUBMIT_ORDER: 'costco_meal_box_submit_order',
  DID_DISPLAY_CONFIRMATION_UPSELL_BANNER:
    'did_display_confirmation_upsell_banner',
  DID_EXPAND_CUSTOMIZE_IT: 'did_expand_customize_it',
  DID_MODIFY_HIDDEN_MEAL_SELECTION: 'did_modify_hidden_meal_selection',
  DID_TAP_CUSTOMIZE_IT_ARROW_LEFT: 'did_tap_customize_it_arrow_left',
  DID_TAP_CUSTOMIZE_IT_ARROW_RIGHT: 'did_tap_customize_it_arrow_right',
  DID_TAP_EXTRA_DECREMENT: 'did_tap_extra_decrement',
  DID_TAP_EXTRA_INCREMENT: 'did_tap_extra_increment',
  DID_TAP_MEAL_DECREMENT: 'did_tap_meal_decrement',
  DID_TAP_MEAL_INCREMENT: 'did_tap_meal_increment',
  DISABLES_FILTER: 'disables_filter',
  DISABLES_SORT: 'disables_sort',
  ENABLES_FILTER: 'enables_filter',
  ENABLES_SORT: 'enables_sort',
  MENU_BACK_CTA: 'menu_back_cta',
  EXITS_MENU: 'exits_menu',
  FINISHES_MEAL_SELECTION: 'finishes_meal_selection',
  INITIATE_CHAT: 'initiate_chat',
  LOGIN_FAILED: 'login_failed',
  MANAGE_DELIVERIES: 'manage_deliveries',
  MODIFIED_MEAL_SELECTION_MEALS_YOU_LOVED:
    'modified_meal_selection_meals_you_loved',
  MODIFY_MEAL_SELECTION: 'modify_meal_selection',
  MY_ORDERS_CTA: 'my_orders_cta',
  OPENS_ACCOUNT_SCREEN: 'opens_account_screen',
  OPENS_EXTRA_DETAIL: 'opens_extra_detail',
  OPENS_EXTRA_DETAIL_DISCLOSURE_TAB: 'opens_extra_detail_disclosure_tab',
  OPENS_MEAL_DETAIL: 'opens_meal_detail',
  OPENS_MEAL_DETAIL_DISCLOSURE_TAB: 'opens_meal_detail_disclosure_tab',
  OPENS_MEAL_PLAN_SETTINGS: 'opens_meal_plan_settings',
  OPENS_MEAL_SUBSTITUTIONS_PREFERENCE: 'opens_meal_substitutions_preference',
  OPENS_MENU: 'opens_menu',
  OPENS_ORDER_HISTORY: 'opens_order_history',
  OPENS_ORDER_RECEIPT: 'opens_order_receipt',
  OPENS_ORDER_TAB: 'opens_order_tab',
  OPENS_ORDER_TRACKING: 'opens_order_tracking',
  OPENS_PREMIUM_MEALS_PREFERENCE: 'opens_premium_meals_preference',
  OPENS_PROMO_SCREEN: 'opens_promo_screen',
  OPENS_REFERRAL_SCREEN: 'opens_referral_screen',
  OPENS_SUPPORT_TAB: 'opens_support_tab',
  ORDER_CONTINUE_CTA: 'order_continue_cta',
  ORDER_DONE_NO_EXTRA: 'order_done_no_extra',
  ORDER_DONE_WITH_EXTRA: 'order_done_with_extra',
  ORDER_RESUME_PLAN_CTA: 'order_resume_plan_cta',
  ORDER_SUMMARY_DISMISS: 'order_summary_dismiss',
  ORDER_SUMMARY_PICK_MEALS: 'order_summary_pick_meals',
  ORDER_SUMMARY_TOTAL_EXPAND: 'order_summary_total_expand',
  ORDER_UPDATE_BOX_SIZE_DISABLED_CTA: 'order_update_box_size_disabled_cta',
  ORDER_UPDATE_BOX_SIZE_ENABLED_CTA: 'order_update_box_size_enabled_cta',
  ORDERS_GET_STARTED: 'orders_get_started',
  ORDERS_LEARN_MORE: 'orders_learn_more',
  PAUSES_MEAL_PLAN: 'pauses_meal_plan',
  PERMANENT_UPTIER_HOME_SCREEN_APPEARS: 'permanent_uptier_home_screen_appears',
  POST_SIGNUP_BREADCRUMBS_DID_APPEAR: 'post_signup_breadcrumbs_did_appear',
  RATES_MENU: 'rates_menu',
  REACTIVATE_MEAL_PLAN_FROM_MEALS_PAGE: 'reactivate_meal_plan_from_meals_page',
  REDEEMS_PROMO: 'redeems_promo',
  REMOVES_PAYMENT_METHOD: 'removes_payment_method',
  REPORT_ISSUE: 'report_issue',
  RESET_PASSWORD_ATTEMPT: 'reset_password_attempt',
  RESET_PASSWORD_FAILED: 'reset_password_failed',
  RESET_PASSWORD_SUCCESSFUL: 'reset_password_successful',
  RESETS_FILTERS: 'resets_filters',
  REVIEW_PROMPT_MODAL_APPEARS: 'review_prompt_modal_appears',
  REVIEW_SUBMITTED: 'review_submitted',
  SCROLLS_MENU_25: 'scrolls_menu_25',
  SCROLLS_MENU_50: 'scrolls_menu_50',
  SCROLLS_MENU_75: 'scrolls_menu_75',
  SCROLLS_MENU_100: 'scrolls_menu_100',
  SHIPPING_ERROR_NO_PO_BOXES: 'shipping-error-no-po-boxes',
  SKIP_ADJUST_CTA: 'skip_adjust_cta',
  SKIP_DELIVERY_CTA: 'skip_delivery_cta',
  SKIPS_WEEK: 'skips_week',
  TAPPED_ADD_MEAL_EXTRA: 'tapped_add_meal_extra',
  TAPPED_FORGOT_PASSWORD: 'tapped_forgot_password',
  TAPPED_MENU_CTA_GET_STARTED: 'tapped_menu_cta_get_started',
  TAPPED_MENU_EXTRAS_TAB: 'tapped_menu_extras_tab',
  TAPPED_MENU_MEALS_TAB: 'tapped_menu_meals_tab',
  TAPPED_REMOVE_MEAL_EXTRA: 'tapped_remove_meal_extra',
  TAPPED_UPSELL_BUTTON: 'tapped_upsell_button',
  TAPS_APPLY_TO_ALL_ORDERS: 'taps_apply_to_all_orders',
  TAPS_CANCEL_MEAL_PLAN: 'taps_cancel_meal_plan',
  TAPS_CHIP: 'taps_chip',
  TAPS_DEACTIVATE_ACCOUNT: 'taps_deactivate_account',
  TAPS_DEACTIVATE_ACCOUNT_CANCEL: 'taps_deactivate_account_cancel',
  TAPS_DEACTIVATE_ACCOUNT_CONFIRM: 'taps_deactivate_account_confirm',
  TAPS_DELETE_ACCOUNT: 'taps_delete_account',
  TAPS_DELETE_ACCOUNT_CANCEL: 'taps_delete_account_cancel',
  TAPS_DELETE_ACCOUNT_CONFIRM: 'taps_delete_account_confirm',
  TAPS_FILTERS: 'taps_filters',
  TAPS_PAUSE_MEAL_PLAN: 'taps_pause_meal_plan',
  TAPS_PERMANENT_UPTIER_HOME_SCREEN: 'taps_permanent_uptier_home_screen',
  TAPS_REVIEW_ANOTHER_MEAL: 'taps_review_another_meal',
  TAPS_SHARE_CODE: 'taps_share_code',
  TAPS_TRY_MEALS_BANNER: 'taps_try_meals_banner',
  TUTORIAL_DISMISSED: 'tutorial_dismissed',
  TUTORIAL_DONE: 'tutorial_done',
  TUTORIAL_REMIND_LATER: 'tutorial_remind_later',
  TUTORIAL_SHOWN: 'tutorial_shown',
  UNSKIPS_WEEK: 'unskips_week',
  USER_CONFIRMED_UPSELL: 'user_confirmed_upsell',
  USER_EDITS_DELIVERY: 'user_edits_delivery',
  USER_EDITS_PLAN: 'user_edits_plan',
  USER_EDITS_WEEK: 'user_edits_week',
  USER_EXITS_REGISTER_FLOW: 'user_exits_register_flow',
  USER_LOGGED_IN: 'user_logged_in',
  USER_LOGGED_OUT: 'user_logged_out',
  USER_REGISTER_DONE_NAME: 'user_register_done_name',
  USER_REGISTER_ENTERED_CONFIRM_PASSWORD:
    'user_register_entered_confirm_password',
  USER_REGISTER_ENTERED_EMAIL: 'user_register_entered_email',
  USER_REGISTER_ENTERED_FIRST_NAME: 'user_register_entered_first_name',
  USER_REGISTER_ENTERED_LAST_NAME: 'user_register_entered_last_name',
  USER_REGISTER_ENTERED_PASSWORD: 'user_register_entered_password',
  USER_REGISTERED: 'user-registered',
  USER_SENT_REFERRAL_BY_EMAIL: 'user_sent_referral_by_email',
  USER_SUBMIT_REGISTRATION: 'user_submit_registration',
  USER_TAPS_RATE_MEAL: 'user_taps_rate_meal',
  VIEW_SUMMARY: 'view_summary',
  VIEWED_POST_PURCHASE_SURVEY: 'viewed_post_purchase_survey',
} as const

export type AnalyticsEventKey = keyof typeof events
export type AnalyticsEvent = (typeof events)[AnalyticsEventKey]

export const sourceIDs = {
  CONFIRMATION_POP_UP: 'confirmation_pop_up',
  EXTRAS_SCREEN: 'extras_screen',
  MENU_TERM: 'menu_term',
  ORDER_SUMMARY: 'order_summary',
  ORDER_TAB: 'order_tab',
  SETTINGS: 'settings',
} as const

export function getSourceIDFromPathname(pathname: string) {
  if (pathname.includes('menu')) {
    return sourceIDs.MENU_TERM
  } else if (pathname.includes('my-orders')) {
    return sourceIDs.ORDER_TAB
  } else if (pathname.includes('account')) {
    return sourceIDs.SETTINGS
  }

  return ''
}
