import { clsx } from 'clsx'

import {
  DATE_FORMATS,
  formatDate,
  getHumanReadableDurationFromNow,
  parseToDate,
} from 'utils/dates'
import { UserTerm } from 'types/internal'

import { useIsNoMenuTerm } from 'hooks/combinedAPI/meals'
import { useUserNextAvailableTermID } from 'hooks/combinedAPI/terms'
import { ReactNode } from 'react'
import { useUser } from 'contexts/user'

const MenuHeader = ({
  backButton,
  filters,
  isHeaderSticky,
  selectedUserTerm,
}: {
  backButton: ReactNode
  filters?: ReactNode
  isHeaderSticky: boolean
  selectedUserTerm: UserTerm
}) => {
  const { user } = useUser()

  const { isNoMenuTerm } = useIsNoMenuTerm({ term: selectedUserTerm })

  const { data: nextTermID } = useUserNextAvailableTermID({ userID: user.id })

  const isPresentTerm = selectedUserTerm.termID === nextTermID

  const deliveryDate = selectedUserTerm.selectedSubTerm
    ? formatDate(selectedUserTerm.selectedSubTerm.deliveryDate, {
        format: DATE_FORMATS.DOW_MONTH_DAY,
      })
    : undefined
  const deliveryText = selectedUserTerm.isSkipped ? 'Skipped' : 'Arrives'
  const timeLeft = selectedUserTerm
    ? getHumanReadableDurationFromNow(
        parseToDate(selectedUserTerm.orderByDate),
        { moreFriendly: true }
      )
    : undefined
  const description = selectedUserTerm.isSkipped
    ? 'left to unskip.'
    : 'left to edit this order'

  return (
    <div className="grid grid-cols-[150px_1fr_150px] items-center justify-between md:grid-cols-[40px_1fr_40px]">
      <div className="shrink-0">{backButton}</div>

      <div>
        <h1
          className={clsx('text-center transition-all md:text-k/28_130', {
            'text-k/36_110': isHeaderSticky,
            'text-k/52_110': !isHeaderSticky,
          })}
        >
          {!isNoMenuTerm && <span>{deliveryText} </span>}
          {deliveryDate}
        </h1>
        {isPresentTerm && timeLeft && (
          <div
            className={clsx(
              'mt-2 text-center text-k/16_125 text-grey-9 transition-all md:hidden',
              {
                'sr-only': isHeaderSticky,
                'not-sr-only': !isHeaderSticky,
              }
            )}
          >
            {timeLeft} {description}
          </div>
        )}
      </div>

      <div className="shrink-0 justify-self-end">{filters}</div>
    </div>
  )
}

export default MenuHeader

export const MenuHeaderWrapper = ({
  children,
  isHeaderSticky,
}: {
  children: ReactNode
  isHeaderSticky: boolean
}) => {
  return (
    <div
      className={clsx(
        'relative z-10 bg-grey-0 pb-4 lg:px-4 md:border-t md:border-grey-3 md:py-4',
        {
          'pt-4': isHeaderSticky,
          'pt-7': !isHeaderSticky,
        }
      )}
    >
      {children}
    </div>
  )
}
