import { MealFilter } from '@tovala/browser-apis-combinedapi'
import { forEach } from 'lodash-es'
import { SelectedMealFilters } from 'types/internal'

export const DISPLAY_STRING_MAP = {
  allergens: 'Allergens',
  diet: 'Diet:',
  dietaryPreferences: 'Dietary Preferences',
  proteinType: 'Protein Type:',
  new: 'New',
  bestSellers: 'Best Sellers',
}

export const menuSortOptions: { displayString: string; key: string }[] = [
  {
    displayString: 'Featured',
    key: 'featured',
  },
  {
    displayString: 'Calories (low to high)',
    key: 'calories',
  },
  {
    displayString: 'Carbs (low to high)',
    key: 'carbs',
  },
  {
    displayString: 'Protein content (high to low)',
    key: 'protein',
  },
  {
    displayString: 'Price per serving (low to high)',
    key: 'price',
  },
]

export const convertSelectedMealFiltersToV2 = (
  filters: SelectedMealFilters
) => {
  const selectedFilters: SelectedMealFilters = {}

  forEach(filters, (value, key) => {
    if (key === DISPLAY_STRING_MAP.dietaryPreferences) {
      selectedFilters[DISPLAY_STRING_MAP.diet] = value
    } else {
      selectedFilters[key] = value
    }
  })
  return selectedFilters
}

export const convertSelectedMealFiltersToV1 = (
  filters: SelectedMealFilters
) => {
  const selectedFilters: SelectedMealFilters = {}

  forEach(filters, (value, key) => {
    if (key === DISPLAY_STRING_MAP.diet) {
      selectedFilters[DISPLAY_STRING_MAP.dietaryPreferences] =
        (value && value.filter((filterKey) => filterKey !== 'high_protein')) ||
        value
    } else if (key === DISPLAY_STRING_MAP.dietaryPreferences) {
      selectedFilters[DISPLAY_STRING_MAP.dietaryPreferences] = value
    } else if (key === DISPLAY_STRING_MAP.allergens) {
      selectedFilters[DISPLAY_STRING_MAP.allergens] = value
    }
  })

  return selectedFilters
}

export const getAllergenFilters = (filters: MealFilter[]) => {
  return filters.filter(
    (filter) => filter.displayString === DISPLAY_STRING_MAP.allergens
  )
}

export const mergeFilters = (filters: MealFilter[]) => {
  const allergenPreferencesIndex = filters.findIndex(
    (filter) => filter.displayString === DISPLAY_STRING_MAP.allergens
  )

  if (allergenPreferencesIndex !== -1) {
    return [...filtersTest, { ...filters[allergenPreferencesIndex] }]
  }
  return filters
}

export const filtersTest: MealFilter[] = [
  {
    displayString: DISPLAY_STRING_MAP.proteinType,
    resultsIncluded: true,
    resultsMatched: 'any',
    filters: [
      {
        id: 'Poultry',
        type: 'category_toggle',
        title: 'Poultry',
        options: [
          {
            displayString: 'Poultry',
            description: '',
            key: 'protein_poultry',
          },
        ],
      },
      {
        id: 'Seafood',
        type: 'category_toggle',
        title: 'Seafood',
        options: [
          {
            displayString: 'Seafood',
            description: '',
            key: 'protein_seafood',
          },
        ],
      },
      {
        id: 'Beef',
        type: 'category_toggle',
        title: 'Beef',
        options: [
          {
            displayString: 'Beef',
            description: '',
            key: 'protein_beef',
          },
        ],
      },
      {
        id: 'Pork',
        type: 'category_toggle',
        title: 'Pork',
        options: [
          {
            displayString: 'Pork',
            description: '',
            key: 'protein_pork',
          },
        ],
      },
      {
        id: 'Vegetarian',
        type: 'category_toggle',
        title: 'Vegetarian',
        options: [
          {
            displayString: 'Vegetarian',
            description: '',
            key: 'protein_vegetarian',
          },
        ],
      },
    ],
  },
  {
    displayString: DISPLAY_STRING_MAP.diet,
    resultsIncluded: true,
    resultsMatched: 'all',
    filters: [
      {
        id: 'Low Sodium',
        type: 'category_toggle',
        title: 'Below 1000mg of sodium',
        options: [
          {
            displayString: 'Below 1000mg of sodium',
            key: 'low_sodium',
          },
        ],
      },
      {
        id: 'Calorie Smart',
        type: 'category_toggle',
        title: 'Calorie Smart',
        options: [
          {
            displayString: 'Calorie Smart',
            description: 'Fewer than 600 calories',
            key: 'calorie_smart',
          },
        ],
      },
      {
        id: 'Carb Conscious',
        type: 'category_toggle',
        title: 'Carb Conscious',
        options: [
          {
            displayString: 'Carb Conscious',
            description: '35g of carbs or fewer',
            key: 'carb_conscious',
          },
        ],
      },
      {
        id: 'High Protein',
        type: 'category_toggle',
        title: 'High Protein',
        options: [
          {
            displayString: 'High Protein',
            description: 'More than 35g of protein',
            key: 'high_protein',
          },
        ],
      },
      {
        id: 'Gluten Friendly',
        type: 'category_toggle',
        title: 'Gluten Friendly',
        options: [
          {
            displayString: 'Gluten Friendly',
            description: 'No gluten-containing ingredients',
            key: 'gluten_friendly',
          },
        ],
      },
      {
        id: 'Mediterranean Diet',
        type: 'category_toggle',
        title: 'Mediterranean Diet',
        options: [
          {
            displayString: 'Mediterranean Diet',
            description: 'Mediterranean Diet Compatible Meal',
            key: 'mediterranean_diet',
          },
        ],
      },
      {
        id: 'Vegetarian',
        type: 'category_toggle',
        title: 'Vegetarian',
        options: [
          {
            displayString: 'Vegetarian',
            description: 'Contains no meat, fish, or fowl',
            key: 'vegetarian',
          },
        ],
      },
      {
        id: 'Non-Surcharged Meal',
        type: 'category_toggle',
        title: 'Non-Surcharged Meal',
        options: [
          {
            displayString: 'Non-Surcharged Meal',
            key: 'non_surcharge_meal',
          },
        ],
      },
    ],
  },
  {
    displayString: DISPLAY_STRING_MAP.bestSellers,
    resultsIncluded: true,
    resultsMatched: 'all',
    filters: [
      {
        id: 'Best Sellers',
        type: 'category_toggle',
        title: 'Best Sellers',
        options: [
          {
            displayString: 'Best Sellers',
            description: 'Most popular items',
            key: 'best_sellers',
          },
        ],
      },
    ],
  },
  {
    displayString: DISPLAY_STRING_MAP.new,
    resultsIncluded: true,
    resultsMatched: 'all',
    filters: [
      {
        id: 'New',
        type: 'category_toggle',
        title: 'New',
        options: [
          {
            displayString: 'New',
            description: 'Newly added to the menu',
            key: 'new',
          },
        ],
      },
    ],
  },
]

export const featuredDietFilters = [
  'carb_conscious',
  'high_protein',
  'calorie_smart',
]

export const mealsInFiltersTest: Record<
  string,
  {
    key: string
    mealsInFilter: number[]
  }[]
> = {
  431: [
    {
      key: 'best_sellers',
      mealsInFilter: [11516, 11502, 11514],
    },
    {
      key: 'new',
      mealsInFilter: [11500, 11501, 11518, 11505, 11532, 11533, 11506, 11531],
    },
    {
      key: 'high_protein',
      mealsInFilter: [
        11516, 11546, 11519, 11520, 11500, 11499, 11525, 11491, 11502, 11522,
        11501, 11526, 11524, 11528, 11492, 11518, 11517, 11515, 11521, 11537,
        11536, 11538, 11512, 11532, 11513, 11534, 11509, 11535, 11533, 11514,
        11539, 11543, 11545, 11542, 11540, 11544,
      ],
    },
    {
      key: 'protein_poultry',
      mealsInFilter: [
        11516, 11519, 11520, 11491, 11522, 11524, 11492, 11518, 11517, 11515,
        11521, 11536, 11538, 11512, 11532, 11513, 11514, 11539, 11543, 11545,
        11542,
      ],
    },
    {
      key: 'protein_beef',
      mealsInFilter: [11500, 11499, 11501, 11503, 11490, 11504],
    },
    {
      key: 'protein_pork',
      mealsInFilter: [11509, 11535, 11510, 11511],
    },
    {
      key: 'protein_seafood',
      mealsInFilter: [11546, 11525, 11526, 11537, 11534, 11533, 11540, 11544],
    },
    {
      key: 'protein_vegetarian',
      mealsInFilter: [11498, 11493, 11497, 11495, 11527, 11496],
    },
  ],
  432: [
    {
      key: 'best_sellers',
      mealsInFilter: [11552, 11571, 11560, 11582, 11562, 11576, 11591],
    },
    {
      key: 'new',
      mealsInFilter: [11554, 11564, 11556],
    },
    {
      key: 'protein_poultry',
      mealsInFilter: [
        11549, 11570, 11571, 11573, 11575, 11576, 11577, 11578, 11582, 11587,
        11591, 11593, 11595, 11598, 11600, 11554,
      ],
    },
    {
      key: 'protein_beef',
      mealsInFilter: [11547, 11556, 11558, 11560, 11562, 11594],
    },
    {
      key: 'protein_pork',
      mealsInFilter: [
        11547, 11548, 11551, 11564, 11566, 11568, 11569, 11586, 11588, 11590,
        11600,
      ],
    },
    {
      key: 'protein_seafood',
      mealsInFilter: [
        11558, 11580, 11581, 11583, 11585, 11586, 11589, 11592, 11596, 11597,
        11599,
      ],
    },
    {
      key: 'protein_vegetarian',
      mealsInFilter: [11550, 11552, 11555],
    },

    {
      key: 'high_protein',
      mealsInFilter: [
        11577, 11588, 11589, 11575, 11583, 11587, 11592, 11549, 11547, 11580,
        11593, 11596, 11581, 11590, 11569, 11595, 11560, 11591, 11568, 11570,
        11576, 11582, 11594, 11566, 11571, 11558, 11556, 11573, 11578, 11598,
        11599, 11600,
      ],
    },
  ],
  433: [
    {
      key: 'best_sellers',
      mealsInFilter: [11620, 11663, 11634, 11641, 11656, 11646, 11618],
    },
    {
      key: 'new',
      mealsInFilter: [11617, 11650, 11649, 11659, 11660],
    },
    {
      key: 'protein_poultry',
      mealsInFilter: [
        11608, 11609, 11622, 11658, 11659, 11632, 11634, 11636, 11637, 11639,
        11641, 11642, 11651, 11655, 11621, 11661, 11663, 11650,
      ],
    },
    {
      key: 'protein_beef',
      mealsInFilter: [11610, 11611, 11618, 11617, 11620, 11626, 11656, 11624],
    },
    {
      key: 'protein_pork',
      mealsInFilter: [
        11612, 11617, 11620, 11621, 11622, 11630, 11646, 11651, 11652, 11654,
        11656, 11657, 11624,
      ],
    },
    {
      key: 'protein_seafood',
      mealsInFilter: [
        11660, 11644, 11645, 11646, 11648, 11652, 11653, 11662, 11664, 11649,
      ],
    },
    {
      key: 'protein_vegetarian',
      mealsInFilter: [11613, 11614, 11615],
    },

    {
      key: 'high_protein',
      mealsInFilter: [
        11624, 11648, 11645, 11630, 11642, 11644, 11641, 11650, 11653, 11654,
        11660, 11664, 11611, 11655, 11649, 11663, 11622, 11637, 11661, 11608,
        11652, 11609, 11636, 11651, 11656, 11620, 11639, 11634, 11659, 11621,
        11657, 11618, 11658, 11646, 11617,
      ],
    },
  ],
  434: [
    {
      key: 'best_sellers',
      mealsInFilter: [11673, 11686, 11689, 11707, 11710, 11682, 11679],
    },
    {
      key: 'new',
      mealsInFilter: [11678, 11692, 11704],
    },
    {
      key: 'protein_poultry',
      mealsInFilter: [
        11670, 11688, 11689, 11691, 11692, 11693, 11695, 11697, 11706, 11708,
        11710, 11715, 11717, 11718,
      ],
    },
    {
      key: 'protein_beef',
      mealsInFilter: [11669, 11671, 11678, 11679, 11681, 11682, 11668],
    },
    {
      key: 'protein_pork',
      mealsInFilter: [11672, 11682, 11684, 11685, 11686, 11695, 11707, 11668],
    },
    {
      key: 'protein_seafood',
      mealsInFilter: [
        11699, 11701, 11703, 11704, 11705, 11709, 11711, 11712, 11713, 11714,
        11716, 11719, 11720,
      ],
    },
    {
      key: 'protein_vegetarian',
      mealsInFilter: [11673, 11675, 11677],
    },

    {
      key: 'high_protein',
      mealsInFilter: [
        11705, 11704, 11714, 11699, 11684, 11720, 11706, 11713, 11707, 11701,
        11717, 11688, 11718, 11691, 11715, 11682, 11708, 11685, 11711, 11719,
        11695, 11692, 11710, 11668, 11678, 11686, 11693, 11697, 11679,
      ],
    },
  ],
}

export const sortedMeals: Record<
  string,
  {
    key: string
    sortedMeals: number[]
  }[]
> = {
  431: [
    {
      key: 'carbs',
      sortedMeals: [
        11542, 11509, 11519, 11540, 11541, 11539, 11546, 11525, 11512, 11513,
        11529, 11530, 11494, 11533, 11531, 11532, 11520, 11500, 11501, 11490,
        11515, 11514, 11492, 11510, 11511, 11543, 11518, 11491, 11505, 11506,
        11502, 11493, 11526, 11544, 11545, 11497, 11498, 11522, 11503, 11521,
        11504, 11528, 11527, 11537, 11508, 11507, 11538, 11499, 11523, 11524,
        11534, 11536, 11535, 11495, 11496, 11516, 11517,
      ],
    },
    {
      key: 'calories',
      sortedMeals: [
        11527, 11542, 11503, 11528, 11504, 11493, 11505, 11510, 11511, 11529,
        11506, 11530, 11512, 11513, 11519, 11546, 11545, 11522, 11521, 11500,
        11501, 11523, 11518, 11531, 11541, 11525, 11495, 11492, 11515, 11496,
        11514, 11544, 11498, 11536, 11494, 11524, 11532, 11543, 11497, 11539,
        11538, 11535, 11534, 11533, 11520, 11540, 11491, 11537, 11516, 11517,
        11509, 11490, 11499, 11508, 11507, 11526, 11502,
      ],
    },
    {
      key: 'protein',
      sortedMeals: [
        11499, 11515, 11514, 11526, 11538, 11512, 11513, 11500, 11501, 11537,
        11491, 11502, 11509, 11492, 11532, 11539, 11519, 11520, 11522, 11521,
        11516, 11546, 11524, 11517, 11536, 11533, 11543, 11545, 11540, 11518,
        11534, 11535, 11544, 11525, 11542, 11528, 11490, 11531, 11541, 11508,
        11507, 11523, 11510, 11511, 11494, 11529, 11530, 11498, 11503, 11504,
        11493, 11497, 11505, 11506, 11527, 11495, 11496,
      ],
    },
    {
      key: 'price',
      sortedMeals: [
        11494, 11493, 11491, 11492, 11490, 11498, 11516, 11519, 11497, 11520,
        11495, 11525, 11502, 11503, 11524, 11518, 11527, 11521, 11536, 11538,
        11512, 11505, 11532, 11509, 11535, 11510, 11507, 11529, 11514, 11539,
        11543, 11545, 11542, 11546, 11499, 11523, 11526, 11528, 11537, 11534,
        11533, 11531, 11540, 11544, 11541, 11513, 11508, 11522, 11517, 11515,
        11496, 11511, 11504, 11506, 11530, 11500, 11501,
      ],
    },
  ],
  432: [
    {
      key: 'carbs',
      sortedMeals: [
        11556, 11600, 11583, 11598, 11569, 11570, 11592, 11594, 11593, 11581,
        11582, 11599, 11558, 11573, 11589, 11587, 11588, 11560, 11577, 11548,
        11566, 11555, 11552, 11550, 11571, 11580, 11564, 11568, 11596, 11597,
        11585, 11595, 11590, 11591, 11547, 11586, 11562, 11549, 11576, 11551,
        11578, 11575, 11554,
      ],
    },
    {
      key: 'calories',
      sortedMeals: [
        11593, 11555, 11556, 11573, 11594, 11548, 11586, 11592, 11570, 11552,
        11600, 11582, 11550, 11597, 11569, 11577, 11591, 11581, 11585, 11549,
        11583, 11595, 11590, 11562, 11599, 11571, 11578, 11560, 11580, 11568,
        11554, 11587, 11598, 11596, 11576, 11564, 11588, 11589, 11558, 11566,
        11547, 11575, 11551,
      ],
    },
    {
      key: 'protein',
      sortedMeals: [
        11558, 11571, 11566, 11578, 11594, 11568, 11570, 11576, 11582, 11598,
        11560, 11591, 11556, 11569, 11573, 11595, 11581, 11590, 11547, 11580,
        11593, 11600, 11596, 11549, 11583, 11587, 11592, 11575, 11577, 11588,
        11589, 11599, 11562, 11586, 11597, 11585, 11554, 11550, 11551, 11564,
        11548, 11552, 11555,
      ],
    },
    {
      key: 'price',
      sortedMeals: [
        11548, 11550, 11549, 11547, 11551, 11552, 11600, 11554, 11555, 11562,
        11598, 11564, 11566, 11568, 11569, 11570, 11571, 11573, 11575, 11576,
        11577, 11578, 11582, 11599, 11583, 11585, 11587, 11588, 11590, 11591,
        11593, 11595, 11560, 11580, 11581, 11586, 11589, 11592, 11596, 11597,
        11558, 11556, 11594,
      ],
    },
  ],
  433: [
    {
      key: 'carbs',
      sortedMeals: [
        11662, 11611, 11652, 11650, 11651, 11649, 11637, 11661, 11660, 11608,
        11659, 11622, 11634, 11646, 11609, 11617, 11630, 11612, 11664, 11663,
        11624, 11639, 11632, 11645, 11615, 11626, 11644, 11614, 11620, 11621,
        11653, 11655, 11654, 11658, 11657, 11642, 11656, 11636, 11610, 11648,
        11613, 11618, 11641,
      ],
    },
    {
      key: 'calories',
      sortedMeals: [
        11662, 11611, 11660, 11649, 11632, 11637, 11610, 11614, 11608, 11650,
        11659, 11663, 11609, 11639, 11622, 11661, 11634, 11646, 11636, 11645,
        11655, 11664, 11615, 11651, 11613, 11654, 11624, 11626, 11617, 11630,
        11653, 11644, 11612, 11658, 11652, 11642, 11641, 11657, 11648, 11621,
        11620, 11618, 11656,
      ],
    },
    {
      key: 'protein',
      sortedMeals: [
        11617, 11646, 11658, 11618, 11621, 11657, 11634, 11659, 11639, 11620,
        11651, 11656, 11609, 11636, 11608, 11652, 11622, 11637, 11661, 11649,
        11663, 11655, 11611, 11641, 11650, 11653, 11654, 11660, 11664, 11630,
        11642, 11644, 11645, 11624, 11648, 11662, 11632, 11612, 11626, 11615,
        11614, 11613, 11610,
      ],
    },
    {
      key: 'price',
      sortedMeals: [
        11612, 11608, 11610, 11609, 11621, 11622, 11624, 11626, 11613, 11614,
        11615, 11630, 11632, 11634, 11636, 11637, 11639, 11641, 11642, 11645,
        11648, 11650, 11651, 11654, 11655, 11656, 11657, 11658, 11659, 11661,
        11662, 11663, 11611, 11620, 11618, 11644, 11646, 11649, 11652, 11653,
        11660, 11664, 11617,
      ],
    },
  ],
  434: [
    {
      key: 'carbs',
      sortedMeals: [
        11669, 11720, 11716, 11714, 11715, 11705, 11706, 11672, 11695, 11688,
        11677, 11703, 11718, 11719, 11678, 11692, 11711, 11670, 11684, 11710,
        11712, 11713, 11701, 11673, 11697, 11699, 11704, 11717, 11691, 11709,
        11708, 11707, 11685, 11682, 11668, 11671, 11681, 11686, 11675, 11693,
        11679, 11689,
      ],
    },
    {
      key: 'calories',
      sortedMeals: [
        11716, 11672, 11718, 11706, 11712, 11709, 11720, 11695, 11677, 11670,
        11692, 11715, 11705, 11688, 11669, 11704, 11710, 11708, 11714, 11684,
        11707, 11713, 11711, 11717, 11701, 11703, 11685, 11699, 11719, 11697,
        11675, 11673, 11678, 11689, 11671, 11681, 11686, 11682, 11691, 11679,
        11668, 11693,
      ],
    },
    {
      key: 'protein',
      sortedMeals: [
        11679, 11697, 11693, 11686, 11678, 11668, 11710, 11692, 11695, 11719,
        11711, 11685, 11708, 11682, 11715, 11691, 11718, 11688, 11717, 11701,
        11707, 11713, 11706, 11720, 11684, 11699, 11714, 11704, 11705, 11671,
        11681, 11712, 11709, 11716, 11669, 11670, 11689, 11703, 11672, 11673,
        11677, 11675,
      ],
    },
    {
      key: 'price',
      sortedMeals: [
        11672, 11670, 11671, 11668, 11673, 11675, 11692, 11704, 11677, 11681,
        11682, 11684, 11685, 11686, 11688, 11689, 11691, 11693, 11695, 11697,
        11701, 11706, 11707, 11708, 11710, 11715, 11717, 11718, 11669, 11679,
        11703, 11705, 11709, 11711, 11712, 11713, 11714, 11716, 11719, 11720,
        11699, 11678,
      ],
    },
  ],
}
