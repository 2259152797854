import { Button, CaretDownIcon, CaretUpIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { MealFilter } from '@tovala/browser-apis-combinedapi'
import { omitBy } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

import {
  ErrorCodeMessageMapCombinedAPI,
  SelectedMealFilters,
} from 'types/internal'
import { events } from '../../../analytics/events'
import {
  getFilteredComponentsCounts,
  getFilteredMenuComponents,
  getHasSelectedFilters,
} from 'utils/menus'
import { track } from 'utils/analytics'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ExtrasAllergenNote from './ExtrasAllergenNote'
import CheckboxRHF from 'components/common/CheckboxRHF'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import Sidebar, {
  SidebarBody,
  SidebarButtons,
  SidebarHeader,
} from 'components/common/Sidebar'
import { MenuComponentsStandardized } from '@tovala/browser-apis-menu-components'

const LOAD_FILTERS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please close and reopen this sidebar to try again.',
    why: "We couldn't load the available meal filters due to a technical issue on our end.",
  },
}

const MenuFilters = ({
  renderButton,
  ...rest
}: {
  allMealFilters: MealFilter[]
  allMealIDs: number[]
  hasLoadMealFiltersError: boolean
  loadMealFiltersError: Error | null
  mealFilters: MealFilter[]
  mealsInFilters: { key: string; mealsInFilter: number[] }[]
  menuComponents: MenuComponentsStandardized
  menuID: string | undefined
  onChangeSelectedMealFilters(filters: SelectedMealFilters): void
  renderButton(opts: { openSidebar(): void }): JSX.Element
  selectedMealFilters: SelectedMealFilters
  termID: number
}) => {
  const [showSidebar, setShowSidebar] = useState(false)

  const openSidebar = () => {
    track(events.TAPS_FILTERS)
    setShowSidebar(true)
  }

  const closeSidebar = () => {
    setShowSidebar(false)
  }

  return (
    <>
      {renderButton({ openSidebar })}

      {showSidebar && (
        <Sidebar onCloseSidebar={closeSidebar}>
          <SidebarHeader onClickClose={closeSidebar}>Filters</SidebarHeader>

          <FiltersForm closeSidebar={closeSidebar} {...rest} />
        </Sidebar>
      )}
    </>
  )
}

export default MenuFilters

const FiltersForm = ({
  allMealFilters,
  allMealIDs,
  closeSidebar,
  hasLoadMealFiltersError,
  loadMealFiltersError,
  mealFilters,
  mealsInFilters,
  menuComponents,
  menuID,
  onChangeSelectedMealFilters,
  selectedMealFilters,
  termID,
}: {
  allMealFilters: MealFilter[]
  allMealIDs: number[]
  closeSidebar(): void
  hasLoadMealFiltersError: boolean
  loadMealFiltersError: Error | null
  mealFilters: MealFilter[]
  mealsInFilters: { key: string; mealsInFilter: number[] }[]
  menuComponents: MenuComponentsStandardized
  menuID: string | undefined
  onChangeSelectedMealFilters(filters: SelectedMealFilters): void
  selectedMealFilters: SelectedMealFilters
  termID: number
}) => {
  const { handleSubmit, register, reset, watch } = useForm({
    defaultValues: selectedMealFilters,
  })

  const values = watch()

  const selectedKeys = Object.values(values).flat()

  const allSelectedFilters = {
    ...selectedMealFilters,
    ...values,
  }

  let resultsQuantity = getFilteredComponentsCounts({
    components: menuComponents,
  })

  let mealIDCount = 0

  if (getHasSelectedFilters(allSelectedFilters)) {
    const { filteredComponents, mealIDsInFilters } = getFilteredMenuComponents({
      allMealIDs,
      selectedMealFilters: allSelectedFilters,
      mealFilters: allMealFilters,
      mealsInFilters,
      menuComponents,
    })

    resultsQuantity = getFilteredComponentsCounts({
      components: filteredComponents,
    })

    mealIDCount = mealIDsInFilters.length
  }

  const onSubmit = (data: SelectedMealFilters) => {
    const selectedFilters = omitBy(data, (filterGroup) => !filterGroup)

    onChangeSelectedMealFilters(selectedFilters)

    closeSidebar()
  }

  const resetFilters = () => {
    onChangeSelectedMealFilters({})

    reset({}, { keepDefaultValues: false })
    track(events.RESETS_FILTERS, {
      sub_menu_id: menuID,
      term_id: termID,
    })
  }

  return (
    <>
      <SidebarBody>
        <div className="px-6 py-10 md:px-4">
          {mealFilters.length > 0 ? (
            <form>
              <div className="space-y-6">
                {mealFilters.map((filterGroup) => {
                  const selectedFilterGroupKeys =
                    values[filterGroup.displayString]

                  const filterCheckboxes = filterGroup.filters.filter(
                    (filter) => filter.type === 'category_toggle'
                  )

                  let appliedFilterDetails: MealFilter['filters'] | '' = ''
                  if (selectedFilterGroupKeys) {
                    appliedFilterDetails = filterGroup.filters.filter((group) =>
                      selectedFilterGroupKeys.includes(group.options[0].key)
                    )
                  }

                  return (
                    <div
                      key={filterGroup.displayString}
                      className="rounded-lg bg-grey-2"
                    >
                      <Collapsible>
                        {({ open }) => {
                          return (
                            <>
                              <CollapsibleTrigger className="w-full p-6 md:px-4">
                                <>
                                  <div
                                    className={clsx(
                                      'flex items-center justify-between',
                                      open && 'border-b border-grey-4 pb-6'
                                    )}
                                  >
                                    <h3 className="text-k/20_125 md:text-k/18_120">
                                      {filterGroup.displayString}
                                    </h3>
                                    {open ? (
                                      <div className="h-6 w-6">
                                        <CaretUpIcon />
                                      </div>
                                    ) : (
                                      <div className="h-6 w-6">
                                        <CaretDownIcon />
                                      </div>
                                    )}
                                  </div>
                                  {appliedFilterDetails && !open && (
                                    <div className="text-left text-k/16_125 text-grey-10">
                                      {appliedFilterDetails
                                        .map(
                                          (filter) =>
                                            filter.options[0].displayString
                                        )
                                        .join(', ')}
                                    </div>
                                  )}
                                </>
                              </CollapsibleTrigger>
                              <CollapsibleContent open={open}>
                                <div className="px-6 pb-6 md:px-4">
                                  {filterCheckboxes.length > 0 && (
                                    <div
                                      aria-labelledby="checkbox-group"
                                      className="space-y-4 text-k/16_125"
                                      role="group"
                                    >
                                      {filterCheckboxes.map((filter) => {
                                        const filterOption = filter.options[0]

                                        const isChecked =
                                          selectedFilterGroupKeys
                                            ? selectedFilterGroupKeys.includes(
                                                filterOption.key
                                              )
                                            : false

                                        return (
                                          <div key={filterOption.key}>
                                            <CheckboxRHF
                                              checked={isChecked}
                                              id={filterOption.key}
                                              label={
                                                <div className="space-y-1">
                                                  <div>
                                                    {filterOption.displayString}
                                                  </div>
                                                  {filterOption.description && (
                                                    <p className="text-k/14_120 text-grey-9">
                                                      {filterOption.description}
                                                    </p>
                                                  )}
                                                </div>
                                              }
                                              name={filterGroup.displayString}
                                              onChange={(e) => {
                                                const event = isChecked
                                                  ? events.DISABLES_FILTER
                                                  : events.ENABLES_FILTER

                                                track(event, {
                                                  filter_id:
                                                    e.currentTarget.value,
                                                  matching_meal_count:
                                                    mealIDCount,
                                                })
                                              }}
                                              register={register}
                                              value={filterOption.key}
                                            />
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )}

                                  {filterGroup.displayString ===
                                    'Allergens' && (
                                    <div className="mt-6 space-y-2 text-k/16_125 text-grey-10">
                                      <p>
                                        Double Check Allergens: Tap on the
                                        meal's image within the menu to see
                                        allergens and ingredients.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </CollapsibleContent>
                            </>
                          )
                        }}
                      </Collapsible>
                    </div>
                  )
                })}
              </div>
              <div className="mt-8">
                <ExtrasAllergenNote />
              </div>
            </form>
          ) : hasLoadMealFiltersError ? (
            <APIErrorDisplay
              error={loadMealFiltersError}
              errorCodeMessageMap={LOAD_FILTERS_ERRORS}
            />
          ) : null}
        </div>
      </SidebarBody>

      {mealFilters.length > 0 && (
        <SidebarButtons>
          <div className="grid grid-cols-2 gap-4 p-6 md:p-4">
            <Button
              buttonStyle="stroke-filled"
              disabled={selectedKeys.length === 0}
              onClick={resetFilters}
              size="large"
            >
              Reset Filters
            </Button>
            <Button onClick={handleSubmit(onSubmit)} size="large" type="submit">
              View {resultsQuantity} Results
            </Button>
          </div>
        </SidebarButtons>
      )}
    </>
  )
}
